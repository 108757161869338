import { BalHeading, BalText } from "@baloise/ds-react";
import {
    DeepPartial,
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import {
    contactFormConfig,
    DealerCodeModal,
    DealerLayout,
    TabsLayout,
} from "@modules/dealer";
import { CompletedCard, Loading, Navigation } from "@modules/shared/components";
import {
    Application,
    LeadInfoKey,
    ModalName,
    PageNames,
} from "@modules/shared/enums";
import {
    findNextInvalidTab,
    getNavigationPages,
    modelDTO,
    validateBusinessRules,
} from "@modules/shared/helpers";
import {
    useCreateLead,
    useResetFlow,
    useSitePaths,
    useWtc,
} from "@modules/shared/hooks";
import {
    AppState,
    appState,
    DealerCodePopup,
    modalState,
} from "@modules/shared/state";
import { PageProps, TabsStatus } from "@modules/shared/types";

const ContactPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const [modal, setModal] = useRecoilState(modalState);
    const { businessRules, getWtcPage } = useWtc(allPages);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [dealerDataIsValid, setDealerDataIsValid] = useState(true);
    const { resetFlow } = useResetFlow(allPages, language, Application.Dealer);
    const createLead = useCreateLead();
    const { prevPage, nextPage } = getNavigationPages(allPages, page);
    const { getSitePath } = useSitePaths();
    const errorPage = getSitePath("500");

    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    const initialFormValues: DeepPartial<FormValues> = useMemo(() => {
        return {
            ...appData,
            DealerContact: appData.DealerContact,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFormState = (formValues: FormValues): void => {
        setAppData({
            ...appData,
            DealerContact: formValues.DealerContact,
        });
    };

    const updateDealerCodeModal = (dealerCodeModalValue: DealerCodePopup) => {
        setAppData({
            ...appData,
            DealerCodePopup: dealerCodeModalValue,
        });
    };

    const handleSubmit = (values: FormValues, tabsStatus: TabsStatus): void => {
        updateFormState(values);
        const nextInvalidTab = findNextInvalidTab(
            tabsStatus,
            page,
            allPages,
            language,
        );

        const wtcPage = getWtcPage(
            validateBusinessRules(businessRules, appData).action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextInvalidTab) {
            navigate(nextInvalidTab.paths[language]);
            return;
        }
        setModal(ModalName.DealerCode);
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        currentPage={page}
                        language={language}
                        nextPageBtnText="dealer.contact.dealercode.action"
                        nextPageAction={() => setModal(ModalName.DealerCode)}
                    />
                );
            }
        },
        [language, page, formProps, setModal],
    );

    const sendDealerData = async (dealerCodeModalValue: DealerCodePopup) => {
        setLoading(true);
        const newAppData = {
            ...appData,
            DealerCodePopup: dealerCodeModalValue,
        };
        const dto = modelDTO(
            newAppData,
            language,
            LeadInfoKey.DealerContact,
            undefined,
            Application.Dealer,
        );

        const { success, statusCode } = await createLead(
            dto,
            Application.Dealer,
        );

        if (success) {
            resetFlow(false);
            navigate(nextPage?.paths[language] ?? "/");
        } else if (statusCode === 400) {
            setDealerDataIsValid(false);
        } else if (statusCode === 500 && errorPage) {
            navigate(errorPage);
        }

        setLoading(false);
    };

    return (
        <DealerLayout
            title={t("dealer.contact.title")}
            page={page}
            language={language}
            allPages={allPages}
        >
            <TabsLayout
                activeTabName={page.name}
                title="dealer.tabs.title"
                description="dealer.tabs.description"
                language={language}
                allPages={allPages}
            >
                {({ tabsStatus }) => {
                    return (
                        <>
                            <div className="intro">
                                <BalHeading level="h1">
                                    {t("dealer.contact.title")}
                                </BalHeading>
                                <BalText>{t("dealer.contact.intro")}</BalText>
                            </div>
                            <Form
                                scrollToFieldError
                                formContext={{
                                    t,
                                    nameSpace: PageNames.DealerContact,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={(values) =>
                                    handleSubmit(values, tabsStatus)
                                }
                                onChange={handleOnChange}
                                fields={contactFormConfig.fields}
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                headerComponent={() =>
                                    renderHeaderComponent(tabsStatus)
                                }
                                enableReinitialize
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevPage}
                                    nextPageBtnText="dealer.contact.dealercode.action"
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
            {loading && <Loading />}
            {modal === ModalName.DealerCode && (
                <DealerCodeModal
                    onClose={() => setModal("")}
                    onSubmit={sendDealerData}
                    onChange={updateDealerCodeModal}
                    dealerDataIsValid={dealerDataIsValid}
                    initialValues={appData.DealerCodePopup}
                />
            )}
        </DealerLayout>
    );
};

export default ContactPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
